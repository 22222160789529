import React from 'react';
import { Link } from 'gatsby';
import { Wrapper } from './nav.css';

const Nav = () => (
  <Wrapper>
    <ul>
      <li>
        <Link to="/privacy">Privacy</Link>
      </li>
      <li>
        <Link to="/accessibility">Accessibility</Link>
      </li>
    </ul>
  </Wrapper>
);

export default Nav;
