import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import posed from 'react-pose';
import { Container, Row, Col } from 'react-grid-system';
import SkipNav from 'components/skipnav';
import { Wrapper } from './header.css';
import Title from 'components/title';
import Nav from 'components/header/nav';
import NavModal from 'components/header/navmodal';
import BrandLogo from '../../assets/images/logo/logo_flyjetcenter.svg';
import NavAlert from 'components/header/navalert';
// import BrandLogo from '../../assets/images/logo/logo_flyjetcenter_white.svg';

const boxShadow = {
  // backgroundColor: '#333333',
  backgroundColor: '#ffffff',
  boxShadow: '0 0 8px 2px #d4d4d7',
  marginBottom: '4rem',
  // height: '100px',
};

// Example of a component-specific page transition
const AnimatedContainer = posed.header({
  enter: {
    y: 0,
    transition: {
      ease: 'easeInOut',
    },
  },
  exit: {
    y: '-100%',
    transition: {
      ease: 'easeInOut',
    },
  },
});

const Header = ({ title }) => (
  <AnimatedContainer style={boxShadow} className="headercss">
    <SkipNav />
    <NavAlert />
    <Wrapper>
      <Container fluid style={{ maxWidth: '1400px' }}>
        <Row>
          <Col col={12} sm={12}>
            <div className="header__inner">
              <Link to="/">
                <img src={BrandLogo} alt={title} className="header__logo" />
              </Link>
              <Title as="span">A Skyways Company</Title>
              <Nav />
              <NavModal />
            </div>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  </AnimatedContainer>
);

Header.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Header;
