import React from 'react';
import { Link } from 'gatsby';
import Modal from 'containers/modal';
import { Wrapper, Inner } from './navmodal.css';

const NavModal = () => (
  <Wrapper>
    <Modal>
      <Inner>
        <ul role="navigation" aria-label="Brand Navigation">
          <li>
            <Link to="/" activeClassName="active">
              Home
            </Link>
          </li>
          <li>
            <Link to="/services" activeClassName="active">
              Services
            </Link>
          </li>
          <li>
            <Link to="/services/charter" activeClassName="active">
              Charter
            </Link>
          </li>
          <li>
            <Link to="/services/aircraft-maintenance" activeClassName="active">
              Maintenance
            </Link>
          </li>
          <li>
            <Link to="/services/facility" activeClassName="active">
              Facility
            </Link>
          </li>
          <li className="hidden">
            <Link to="/articles" activeClassName="active">
              Articles
            </Link>
          </li>
          <li>
            <Link to="/contact" activeClassName="active">
              Contact
            </Link>
          </li>
          <li>
            <Link to="/privacy" activeClassName="active">
              Privacy
            </Link>
          </li>
          <li>
            <Link to="/accessibility" activeClassName="active">
              Accessibility
            </Link>
          </li>
        </ul>
      </Inner>
    </Modal>
  </Wrapper>
);

export default NavModal;
