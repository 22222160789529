import React from 'react';
import { Link } from 'gatsby';
import { Container, Row, Col } from 'react-grid-system';
import { Wrapper } from './navalert.css';

// const buttonReset = {
//   border: '0',
//   background: 'none',
//   fontFamily:
//     '-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
//   lineHeight: '1',
//   fontSize: '18px',
//   fontWeight: '300',
//   cursor: 'pointer',
// };

const NavAlert = () => (
  <Wrapper>
    <Container>
      <Row>
        <Col col={12} sm={12}>
          <div className="alert-accordion">
            <span>
              Fly Jet Center is responding to COVID-19 with the safety of you
              and our employees as our #1 priority! FJC is...
            </span>
            <span className="alert-banner__button-text">
              <Link to="/news/covid-19">Learn more about our guidelines</Link>
            </span>
          </div>
        </Col>
      </Row>
    </Container>
  </Wrapper>
);

export default NavAlert;
