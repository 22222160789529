// This does not support nested pages (level 2 and up)
// If you're working with deeply nested pages, remove this or rework it.

export default ({
  location,
  canonical,
  siteUrl,
  pageTitle,
  siteTitle,
  pageTitleFull,
}) => {
  const isSubPage = pageTitle && location.pathname !== '/';
  const isArticle = location.pathname.includes('/articles/');

  let schema = [
    {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      url: canonical,
      name: pageTitle || siteTitle,
      alternateName: pageTitleFull,
    },
  ];

  if (isSubPage && !isArticle) {
    schema.push({
      '@context': 'http://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: [
        {
          '@type': 'ListItem',
          position: 1,
          item: {
            '@id': siteUrl,
            name: siteTitle,
          },
        },
        {
          '@type': 'ListItem',
          position: 2,
          item: {
            '@id': canonical,
            name: pageTitle,
          },
        },
      ],
    });
  }

  if (isSubPage && isArticle) {
    schema.push({
      '@context': 'http://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: [
        {
          '@type': 'ListItem',
          position: 1,
          item: {
            '@id': siteUrl,
            name: siteTitle,
          },
        },
        {
          '@type': 'ListItem',
          position: 2,
          item: {
            '@id': siteUrl + '/articles',
            name: 'Articles',
          },
        },
        {
          '@type': 'ListItem',
          position: 3,
          item: {
            '@id': canonical,
            name: pageTitle,
          },
        },
      ],
    });
  }

  // if (isArticle) {
  //   schema.push({
  //     '@context': 'http://schema.org',
  //     '@type': 'Article',
  //     headline: pageTitle,
  //     alternativeHeadline: pageTitleFull,
  //     image: 'http://example.com/image.jpg',
  //     author: 'Jason Ruyle',
  //     award: 'Best article ever written',
  //     editor: 'Craig Mount',
  //     genre: 'search engine optimization',
  //     keywords: 'seo sales b2b',
  //     wordcount: '1120',
  //     publisher: 'Book Publisher Inc',
  //     url: 'http://www.example.com',
  //     datePublished: '2015-09-20',
  //     dateCreated: '2015-09-20',
  //     dateModified: '2015-09-20',
  //     description: 'We love to do stuff to help people and stuff',
  //     articleBody:
  //       'You can paste your entire post in here, and yes it can get really really long.',
  //   });
  // }

  return schema;
};
