import { createGlobalStyle } from 'styled-components';
import theme from 'constants/theme';
import MEDIA from 'helpers/mediaTemplates';
import fontFiles from 'assets/fonts/fonts';

export default createGlobalStyle`
  @font-face {
    font-family: "Xolonium Bold";
    font-style: normal;
    font-weight: bold;
    font-display: auto;
    src:
      local("Xolonium Bold"),
      local("Xolonium-Bold"),
      url(${fontFiles.XoloniumBoldWOFF2}) format("woff2"),
      url(${fontFiles.XoloniumBoldWOFF}) format("woff");
  }

  @-ms-viewport {
    width: device-width;
  }

  html {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    -ms-overflow-style: scrollbar;
  }

  *,
  *::before,
  *::after {
    -webkit-box-sizing: inherit;
            box-sizing: inherit;
  }

  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  html {
    font-size: 62.5%;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }

  body {
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    line-height: 1;
    font-size: ${theme.baseFontSize};
    color: ${theme.colors.base};
    background-color: #fff;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    -o-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    // text-rendering: optimizeLegibility;
    text-rendering: optimizeSpeed;
    -webkit-font-feature-settings: "pnum";
    font-feature-settings: "pnum";
    font-variant-numeric: proportional-nums;
    font-weight: 300;
  }

  #___gatsby {
    line-height: ${theme.baseLineHeight};
  }

  h1,
  h2,
  h3 {
    font-family: "Xolonium Bold";
    text-transform: uppercase;
  }

  ul {
    list-style: none;
  }

  blockquote, q {
    quotes: none;
  }

  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  a {
    color: ${theme.colors.primary};
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }

  pre {
    display: block;
    padding: 2rem;
    margin-top: 4rem;
    overflow: auto;
    font-size: 85%;
    line-height: 1.45;
    border-radius: 5px;
    color: ${theme.colors.base};
    border: 1px solid #ddd;
    font-family: "SFMono-Regular",Consolas,"Liberation Mono",Menlo,Courier,monospace;
  }

  video {
    max-width: 100%;
  }

  p {
    margin-bottom: 2rem;
  }

  .color-primary {
    color: ${theme.colors.base};
  }

  .text-center {
    text-align: center;
  }

  .text-center pre {
    text-align: left;
  }

  .hidden {
    display: none;
  }

  .img-responsive {
    max-width: 100%;
    height: auto;
  }

  ${MEDIA.MIN_TABLET`
    .gallery__work {
      .gallery__items {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 4rem;
      }
    }
  `};

  ${MEDIA.MIN_LAPTOP`
    .gallery__work {
      .gallery__items {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 4rem;
      }
    }
  `};

  ${MEDIA.MIN_TABLET`
    .gallery__work {
      .gatsby-image-wrapper {
        max-height: 228px;
        min-height: 228px;
      }
    }
  `};

  :root {
    --reach-dialog: 1;
  }

  [data-reach-dialog-overlay] {
    // background: hsla(0, 0%, 0%, 0.33);
    background: hsla(0, 0%, 0%, 0.8);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    z-index: 100;
  }

  [data-reach-dialog-content] {
    width: 50vw;
    margin: 20vh auto;
    //margin: 10vh auto;
    background: transparent;
    padding: 0;
    outline: none;
  }

  label span {
    font-size: 1rem;
  }

  textarea {
    overflow: auto;
    resize: vertical;
  }

  textarea.form-control {
    height: inherit;
  }

  .form-group {
    margin-bottom: 1rem;
  }

  .form-control {
    background-clip: padding-box;
    background-color: #ffffff;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    color: #495057;
    display: block;
    font-size: 1.7rem;
    height: calc(3.25rem + 2px);
    line-height: 1.5;
    padding: .375rem .75rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    width: 100%;
    font-family: monospace;
  }

  .justify-content-end {
    justify-content: flex-end!important;
  }

  [type=reset], [type=submit], button, html [type=button] {
    -webkit-appearance: button;
  }

  sub {
    vertical-align: sub;
    font-size: smaller;
  }

  sup {
    vertical-align: super;
    font-size: smaller;
  }

  blockquote {
    border-left: 5px solid #1f75ab;
    padding-left: 2rem;
  }
`;
