import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Wrapper = styled.nav`
  display: none;

  ${MEDIA.TABLET`
    display: block;
  `};
`;

export const Inner = styled.div`
  text-align: center;
  li {
    font-size: 3rem;
    line-height: 1.4;

    a {
      color: #ffffff;
    }
  }
`;
