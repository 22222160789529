import React from 'react';
// import PropTypes from 'prop-types';
import { graphql, StaticQuery } from 'gatsby';
import { Container, Row, Col } from 'react-grid-system';
import { Wrapper, WrapperTop, TitleSpan } from './footer.css';
import Title from 'components/title';
import Nav from 'components/footer/nav';

const Footer = () => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            siteTitleLegal
          }
        }
      }
    `}
    render={data => (
      <Wrapper>
        <Container>
          <Row>
            <Col col={12} sm={12}>
              <WrapperTop>
                <Nav />
                <Title tag="span" size="small">
                  <TitleSpan>
                    Copyright © 2020 {data.site.siteMetadata.siteTitleLegal}.
                  </TitleSpan>
                </Title>
              </WrapperTop>
            </Col>
          </Row>
        </Container>
      </Wrapper>
    )}
  />
);

// Footer.propTypes = {
//   title: PropTypes.string.isRequired,
//   data: PropTypes.object.isRequired,
// };
export default Footer;
