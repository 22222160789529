import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Wrapper = styled.div`
  display: none;

  ${MEDIA.MIN_TABLET`
     display: flex;
     background: #ffe7e8;
     border-color: #e66465;
     border-bottom: 1px solid;
     padding: 1rem 0;
  `};

  a {
    color: #111111;
    font-weight: 400;
  }
`;
