import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Wrapper = styled.div`
  .header__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0rem 0;
  }

  a {
    // color: #fefefe;
    color: #1f75ab;
    transition: color 0.2s ease;
    text-decoration: none;
    font-weight: 700;
    // overflow: hidden;

    &:hover {
      // color: #ffffff;
      color: #1f75ab;
    }

    &.active {
      // color: #ffffff !important;
      color: #1f75ab !important;
      font-weight: 700;
    }
  }

  span {
    display: none;
    color: #666666;
    flex-grow: 1;
    font-style: italic;
  }

  ${MEDIA.MIN_DESKTOP`
    span {
      display: block;
    }
  `};

  .header__logo {
    // height: 75px;
    // height: 85px;
    height: 100px;
    margin-top: -5px;
    margin-left: -25px;
  }
`;
