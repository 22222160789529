import React from 'react';
import { Link } from 'gatsby';
import { Wrapper } from './nav.css';

const Nav = () => (
  <Wrapper>
    <ul>
      <li>
        <Link to="/services" activeClassName="active">
          Services
        </Link>
      </li>
      {/* <ul> */}
      <li>
        <Link to="/services/charter" activeClassName="active">
          Charter
        </Link>
      </li>
      <li>
        <Link to="/services/aircraft-maintenance" activeClassName="active">
          Maintenance
        </Link>
      </li>
      <li>
        <Link to="/services/facility" activeClassName="active">
          Facility
        </Link>
      </li>
      {/* </ul> */}
      <li>
        <Link to="/contact" activeClassName="active">
          Contact
        </Link>
      </li>
    </ul>
  </Wrapper>
);

export default Nav;
